<template>
  <div class="cr-second-row">
    <Authority v-bind="$props" v-on="$listeners" />
    <Time v-bind="$props" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-second-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<script>
import Authority from "./Authority.vue";
import Time from "./Time.vue";

export default {
  components: { Authority, Time },
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
