<template>
  <div class="black--text mr-1">
    <span>{{ joinType }} 시간:</span>
    <span class="ml-2">{{ joinRequestTime }}</span>
  </div>
</template>

<script>
import { getDateWithTimeSecond } from "@/commons/utils/moment";

export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getTime() {
      const { joinStatus, joinTimeMillis, joinRequestTimeMillis } = this.member;
      if (joinStatus === "ACCEPTED") return joinTimeMillis || 0;
      return joinRequestTimeMillis || 0;
    },
    joinRequestTime() {
      return getDateWithTimeSecond(this.getTime);
    },
    joinType() {
      switch (this.member?.joinStatus) {
        case "ACCEPTED":
          return "가입";
        case "INVITED":
          return "초대";
        case "PENDING":
          return "신청";
        default:
          break;
      }

      return "";
    }
  }
};
</script>
